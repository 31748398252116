const config = {
  apiKey: 'AIzaSyByNHe53HQcacRLqe0ol1yg3y2se1He9Ic',
  authDomain: 'kanban-b362a.firebaseapp.com',
  databaseURL: 'https://kanban-b362a.firebaseio.com',
  projectId: 'kanban-b362a',
  storageBucket: 'kanban-b362a.appspot.com',
  messagingSenderId: '902104426562',
  appId: '1:902104426562:web:67d65af7dbe423d0b1a5db',
  measurementId: 'G-49WQ12J2L4',
};

// eslint-disable-next-line import/prefer-default-export
export { config };
